@import '../../theme';

.EnvWarning {
  width: 100%;
  max-width: 520px;
  margin: 8px auto;
  padding: 8px;

  display: flex;
  align-items: center;

  p {
    font-size: 12px;
    margin: 0;
    margin-left: 16px;
  }
}
