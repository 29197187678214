@import '../../theme';

.hpage-payment-state {
  width: 100%;
  max-width: 600px;
  margin: auto;

  .divider {
    width: 100%;
    max-width: 350px;
    height: 1px;

    margin: 32px auto;

    background-color: #eeeeee;
  }

  .step-title {
    margin: 16px 0 32px 0;
    color: $gray-dark;

    &-icon {
      margin-left: 8px;
    }

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      font-size: 18px;
      text-transform: uppercase;

      margin-bottom: 16px;
    }

    .step-subtitle {
      font-size: 14px;
    }
  }

  .step-result {
    margin-bottom: 32px;

    &-text {
      margin-bottom: 16px;
      font-size: 14px;

      .transaction-number {
        color: $hipay-performance-violet;
      }
    }

    &-code {
      width: 100%;

      margin: auto;

      text-align: left;
      overflow-x: auto;

      background-color: $gray-x-light;
      border-radius: 2px;

      .__json-pretty__ {
        margin-left: 8px;
      }

      .__json-string__ {
        color: $hipay-performance-violet;
      }

      &-error {
        .__json-string__ {
          color: $hipay-red;
        }
      }
    }
  }
}

.actions-hpage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 32px 0;
  text-align: center;

  .action-button {
    margin: 0 16px;
  }
}
