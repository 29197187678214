// ############  COLORS

$hipay-performance-violet: #2C0A64;
$hipay-red: #CB2B0B;
$hipay-yellow: #ffba00;

$gray-dark: #696969;
$gray-medium: #bbbbbb;
$gray-light: #e8e8e8;
$gray-x-light: #fafafa;

// ############  BREAKPOINTS
$bp-xs: 360px;
$bp-s: 420px;
$bp-sm: 600px;
$bp-smd: 760px;
$bp-md: 920px;
$bp-lg: 1280px;
