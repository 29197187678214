@import '../../theme.scss';

.Home {
  display: flex;
  flex-wrap: wrap;
  padding-top: 16px;

  .panel-left {
    flex: 1 0 65%;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 32px;

    &-wrapper {
      width: 100%;
      max-width: 600px;

      & > div {
        margin-bottom: 16px;
      }
    }
  }

  .panel-right {
    flex: 1 0 35%;
    display: flex;
    flex-direction: column;

    padding: 32px;

    background-color: $gray-x-light;
  }

  @media (max-width: $bp-md) {
    flex-direction: column;

    .panel-left {
      order: 2;

      padding: 16px;
    }
    .panel-right {
      order: 1;

      width: 100%;
      max-width: 600px;
      margin: auto;
      margin-bottom: 16px;
      padding: 16px;
    }
  }
}
