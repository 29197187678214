@import '../../theme.scss';

.Cart {
  .divider {
    width: 90%;
    height: 1px;
    background-color: #eeeeee;
    margin: 24px auto;
  }

  .cart-items {
    margin: 56px 0;
  }

  .total {
    display: flex;
    justify-content: space-around;

    margin-bottom: 8px;

    font-size: 26px;
    text-transform: uppercase;
  }

  @media (max-width: $bp-md) {
    .divider {
      margin: 16px auto;
    }

    .cart-items {
      margin: 24px 0;
    }

    .total {
    }
  }
}
