.PaymentDetails {
  padding-top: 1px;

  .payment-details-title{
    text-transform: uppercase;
  }

  .hosted-payment-form {
    margin-top: 16px;
  }

  .hipay-form {
    margin: 16px auto;
    width: 100%;
    max-width: 640px;
  }
}
