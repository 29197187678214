.ConfigEnvs {
  .environment,
  .tokenization,
  .transaction {
    margin-bottom: 32px;
  }

  .help {
    margin: 16px 0;
    font-size: 14px;
  }

  .link {
    cursor: pointer;
  }

  .warning {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    p {
      font-size: 14px;
      margin: 0;
      margin-left: 16px;
    }
  }
  [class^='MuiFormHelperText-root'] {
    text-align: left;
  }
}
