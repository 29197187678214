@import '../../theme';

.Header {
  padding: 0 16px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .link {
    .button-text {
      margin-left: 8px;
    }
  }

  .uppercase {
    text-transform: uppercase;
  }

  .disabled-link {
    pointer-events: none;
  }

  .flags {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 40px;

    .flag-icon {
      width: 40px;
      height: 40px;

      cursor: pointer;
    }
  }

  @media (min-width: $bp-sm) {
    .nav-link,
    .flags {
      min-width: 280px;
    }
  }

  @media (max-width: $bp-sm) {
    &.lang-opened {
      .hipay-logo {
        display: none;
      }

      .button-text {
        display: none;
      }
    }
  }

  @media (max-width: $bp-s) {
    .hipay-logo {
      display: none;
    }

    &.lang-opened {
      .nav-link,
      .hipay-logo {
        display: none;
      }

      .flags {
        width: 100%;
      }
    }
  }
}
