@import '../../theme';

.app-body {
  color: $gray-dark;

  .route-content {
    .page-content {
      padding: 32px;
      padding-top: 16px;
      max-width: 1200px;
      margin: auto;

      @media (max-width: $bp-sm) {
        padding: 16px;
      }
    }
  }

  .formControlLabel {
    color: #737373;
    font-size: 16px;
  }

  .radioGroup {
    margin-top: 8px;
  }
}

h2 {
  color: $hipay-performance-violet;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;

  padding-bottom: 8px;
  margin-top: 16px;

  border-bottom: solid 1px #eeeeee;
}

h3 {
  color: $gray-dark;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;

  line-height: 1;

  margin-bottom: 16px;
  margin-top: 8px;
}
