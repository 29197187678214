@import '../../theme.scss';

.ConfigIntegrationType {
  display: flex;
  flex-wrap: wrap;

  font-size: 14px;
  color: black;

  margin-left: -16px;
  margin-right: -16px;

  .integration-type {
    flex: 50;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    min-width: 260px;

    position: relative;

    margin: 16px;
    padding: 16px;

    cursor: pointer;

    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    border-left: 4px solid white;

    &:hover {
      transform: scale(1.02);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25), 0 4px 6px rgba(0, 0, 0, 0.22);
    }
    &:focus {
      outline: none;
    }

    &.selected {
      border-left-color: $hipay-performance-violet;

      h3 {
        color: $hipay-performance-violet;
      }
    }
  }

  h3 {
    font-size: 18px;
    border-bottom: none;
  }
  img {
    width: 100%;
    max-width: 500px;
    margin-top: 8px;
    margin-bottom: 16px;
  }

  .notes {
    width: 100%;
    display: flex;
    justify-content: space-around;

    margin-top: 16px;

    .category {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .dots {
      display: flex;
      justify-content: space-around;

      margin-top: 12px;
    }
    .dot {
      height: 14px;
      width: 14px;
      border-radius: 50%;

      margin: 0 4px;

      background-color: white;

      border: 1px solid $hipay-yellow;

      &.filled {
        background-color: $hipay-yellow;
      }
    }
  }

  .hpage-config {
    display: flex;
    width: 100%;
    margin: 15px;
  }
}
