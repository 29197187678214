@import '../../theme';

.HostedPageButton {
  margin: 8px auto;
  text-align: center;

  .hpage-payment-info {
    margin: 25px;
  }

  .button-base {
    max-width: 520px;
  }
}
