@import '../../theme';

.OrderButton {
  margin: 8px auto;
  text-align: center;

  .button-base {
    max-width: 520px;
  }
}
