@import '../../theme.scss';

.ConfigPaymentGlobal {
  .container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .select {
      width: 100%;
      flex: 1;
    }

    .help-text {
      flex: 1;
      margin-left: 32px;
      font-size: 14px;
    }

    @media (max-width: $bp-smd) {
      flex-wrap: wrap;

      .select,
      .help-text {
        flex-basis: 100%;
      }

      .select {
        margin-bottom: 16px;
      }

      .help-text {
        margin-left: 0;
      }
    }
  }
}
