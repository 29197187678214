@import '../../theme';

.FakeCheckoutStep {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;

  .icon {
    color: $hipay-performance-violet;
  }
}
