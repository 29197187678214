@import '../../theme.scss';

.ConfigStyles {
  .colors {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(3, 1fr);

    margin-bottom: 16px;
  }

  .inputs {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(3, 1fr);
  }

  .radioGroup {
    margin-top: 200px;
  }

  @media (max-width: $bp-smd) {
    .colors,
    .inputs {
      grid-template-columns: repeat(2, 50%);
    }
  }

  @media (max-width: $bp-sm) {
    .colors,
    .inputs {
      grid-template-columns: 1fr;
    }
  }

  .code-theme-container {
    .code-theme {
      display: flex;

      .input {
        display: flex;
        align-items: center;
      }

      .help {
        margin: 16px 0px 8px 38px;

        .themeCode {
          position: relative;
          display: flex;
          gap: 6px;

          .themeCode_text {
            transition: color 0.1s linear;

            &:hover {
              cursor: pointer;
              color: $hipay-performance-violet;
            }
          }

          .copied-msg {
            position: absolute;
            opacity: 0;
            top: -3px;
            left: -54px;
            z-index: 5;
            color: $gray-x-light;
            background-color: $hipay-performance-violet;
            padding: 2px 4px 2px 4px;
            font-size: 0.6rem;
            border-radius: 5px;
            -webkit-transition:
              opacity 600ms,
              visibility 600ms;
            transition:
              opacity 600ms,
              visibility 600ms;
          }
        }
      }
    }

    @media (max-width: $bp-smd) {
      .code-theme {
        flex-direction: column;
      }
    }
  }
}
