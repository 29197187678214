@import '../../theme';

.CartItem {
  display: grid;
  grid-template-columns: 100px auto auto;
  grid-template-rows: 30px auto;
  grid-column-gap: 10px;
  align-items: center;
  margin-bottom: 16px;

  .image {
    grid-row-start: span 2;
    align-self: start;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 60px;
      height: 60px;
    }
  }

  .name {
    font-size: 16px;
    text-transform: uppercase;
  }

  .amount {
    justify-self: end;
  }

  .description {
    grid-column-start: 2;
    grid-column-end: 4;
    align-self: start;

    font-size: 14px;
    color: $gray-medium;
  }
}
