@import '../../theme';

.LoadingSpinner {
  width: 30px;
  height: 30px;
  margin: auto;

  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid; /* Blue */
  border-top-color: $hipay-performance-violet;
  border-radius: 50%;

  animation: spin 1.5s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
