@import '../../theme';

.Settings {
  color: #737373;

  .sdkLoading {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.5);

    .loading-container {
      margin-top: 35vh;
      text-align: center;
      text-transform: uppercase;
      color: black;
    }
  }

  .MuiButton-contained.Mui-disabled {
    color: rgba(0, 0, 0, 0.24);
  }
  .Card {
    margin: 0;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .save-row {
    display: flex;
    justify-content: center;
    max-width: 800px;

    margin: auto;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .tabs {
    width: 100%;
    background-color: $gray-x-light;
  }

  .tab {
    flex-grow: 1;
    font-size: 14px;
    max-width: 264px;

    @media (max-width: $bp-sm) {
      font-size: 12px;
    }

    @media (max-width: $bp-xs) {
      font-size: 11px;
    }
  }

  .tab-content-container {
    padding: 16px;

    @media screen and (max-width: 360px) {
      padding: 16px 8px;
    }

    .tab-content {
      max-width: 1280px;
      margin: auto;

      display: flex;
      flex-direction: column;
    }
  }

  h3 {
    font-size: 14px;
    font-weight: 600;

    padding-bottom: 8px;
    margin-top: 16px;

    color: black;
    //border-bottom: solid 1px #eeeeee;
  }

  .helperText {
    margin-bottom: 24px;
    font-size: 14px;

    border-left: solid 4px $gray-light;
    padding-left: 8px;
  }

  .configBlock {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 8px 16px;
  }

  .ExpansionPanel {
    box-shadow:
      0px 1px 3px 0px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.1) !important;
  }

  .summaryRoot {
    min-height: 48px !important;

    .panelClosed {
      border-bottom: none !important;
    }
  }

  .panelDetails {
    padding: 16px 24px;
    @media screen and (max-width: 360px) {
      padding: 8px 16px;
    }
    & > div:first-child {
      width: 100%;
    }
  }

  .block-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .block {
    display: flex;
    flex-direction: column;
    flex-basis: 45%;

    @media (max-width: $bp-smd) {
      flex-basis: 100%;
    }
  }
}

.max-content-width label {
  width: max-content !important;
}

.card-networks {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  align-content: flex-start;
  margin-top: 18px;
  margin-bottom: 6px;
}

.card-brand-checkbox-container {
  display: flex;
  align-items: center;
  gap: 6px;
}

.network-checkbox-info {
  font-size: 0.8rem !important;
}
